import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { catchError, concatMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/home/shared/services/auth.service';

@Injectable({ providedIn: 'root' })
export class CallbackResolver {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  resolve(): Observable<void> {
    return this.authService.handleAuthCallback().pipe(
      catchError(_ => {
        this.authService.logout();
        return EMPTY;
      }),
      concatMap(result => {
        if (result.loggedIn) {
          this.router.navigate([result.targetUrl]);
          return EMPTY;
        } else {
          this.authService.login();
          return EMPTY;
        }
      }),
      take(1)
    );
  }
}
