import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { LoadingSpinnerComponent } from './shared/components/loading/loading-spinner.component';
import { LoadingModule } from './shared/components/loading/loading.module';
import { CallbackResolver } from './shared/resolvers/callback.resolver';
import { LogInterceptor } from './shared/interceptors/log.interceptor';
import { ServerMaintenanceDialogModule } from './shared/components/server-maintenance-dialog/server-maintenance-dialog.module';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { AssetInterceptor } from './shared/interceptors/asset.interceptor';
import { MultiPartInterceptor } from './shared/interceptors/multi-part.interceptor';
import { GraphQLInterceptor } from './shared/interceptors/graphql.interceptor';
import * as Sentry from '@sentry/angular';
import { EffectsModule } from '@ngrx/effects';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS
} from '@angular/material-luxon-adapter';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

export const ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'auth' },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'loading', component: LoadingSpinnerComponent },
  {
    path: 'callback',
    resolve: {
      callback: CallbackResolver
    },
    component: LoadingSpinnerComponent
  },
  { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
  { path: '**', redirectTo: 'auth' }
];

@NgModule({
  declarations: [AppComponent],
  exports: [LoadingSpinnerComponent],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    LoadingModule,
    MatSnackBarModule,
    MatDatepickerModule,
    ServerMaintenanceDialogModule,
    RouterModule.forRoot(ROUTES, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always'
    }),
    EffectsModule.forRoot([]),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      connectInZone: true
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.environment !== 'local',
      registrationStrategy: 'registerWhenStable:5000'
    })
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AssetInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MultiPartInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GraphQLInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
